import { Component } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { environment } from '../environments/environment';
import { filter } from 'rxjs';

declare var gtag : any;

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'myothernest';

  static PRODUCTION : boolean = environment.production;
  static HIDE_BUTTONS : boolean = environment.hide_buttons;

  constructor (router : Router) {
    if(typeof window !== 'undefined' && AppComponent.PRODUCTION){
      const navEndEvent = router.events.pipe(
        filter(event => event instanceof NavigationEnd),
      );
  
      navEndEvent.subscribe((event: NavigationEnd) => {
        gtag('config', 'G-D21DQB9Q3W', {
          'page_path': event.urlAfterRedirects
        })
      })
    }
    

    if (AppComponent.PRODUCTION) {
      console.log = function () {};
      console.error = function () {};
      console.warn = function () {};
    }

  }

}
