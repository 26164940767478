import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {


  private _isHiddingImage : boolean = false;
  private dataSubject = new BehaviorSubject<boolean>(this._isHiddingImage);
  private data$: Observable<boolean> = this.dataSubject.asObservable();

  
  constructor() { 

  }


  hideImg(){
    this._isHiddingImage = true;
    this.dataSubject.next(true);
  }

  showImg(){
    this._isHiddingImage = false;
    this.dataSubject.next(false);
  }

  isHiddenImage () : boolean {
    return this._isHiddingImage;
  }

  public observable () : Observable<boolean> {
    return this.data$;
  }

}
