import { Injectable } from '@angular/core';
import { Iuser } from '../interfaces/user.interface';
import { HttpHeaders } from '@angular/common/http';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { AppComponent } from '../../app.component';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private readonly _itemName : string = 'token'
  private _user ?: Iuser;
  constructor(private cookieService: SsrCookieService) { }

  isLogged() : boolean{
    return this.cookieService.check(this._itemName);
  }

  login(user : Iuser, token : string){
    this._user = user;
    this.cookieService.set(this._itemName, token);
  }

  logout(){
    delete this._user;
    this.cookieService.delete(this._itemName);
  }

  getId(){
    return AppComponent.PRODUCTION ? '' : 'Myothernest';
  }

  getHttpHeaders(){
    return new HttpHeaders(
      {
        'Authorization' : `Token ${this.getToken()}`
      }
    )
  }

  getUser() : Iuser|undefined {
    return this._user;
  }

  getToken(){
    return this.cookieService.get(this._itemName);
  }

}
